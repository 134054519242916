import "./App.css";
import LandingPage from "./mainPage/pages/LandingPage/Landingpage";
import Intro from "./mainPage/pages/Intro/Intro";
import { useCallback, useEffect, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Route, BrowserRouter as Router, Routes ,useLocation} from "react-router-dom";
import ConstuctionLandig from "./Pages/Construction/Landing/ConstructionLanding";
import EntertainmentLanding from "./Pages/Entertainment/Landing/EntertainmentLanding";
import HealthcareLanding from "./Pages/Healthcare/Landing/HealthcareLanding";
import HomeServicesLanding from "./Pages/HomeServices/Landing/HomeServicesLanding";
import PetCareLanding from "./Pages/Petcare/Landing/PetcareLanding";
import EventsLanding from "./Pages/events/Landing/EventsLanding";
import SignupMain from "./Authentication/Signup/Main/Main";
import LoginMain from "./Authentication/Login/Main/Main";
import Modal from "./utils/modal/Modal";
import Completed from "./Authentication/Completed/Completed";
import TermsAndConditions from "./Authentication/Signup/Documents/TermAndConditions/TermAndConditions";
import PrivacyPolicy from "./Authentication/Signup/Documents/PrivacyPolicy/PrivacyPolicy";
import TermOfUse from "./Authentication/Signup/Documents/TermOfUse/TermOfUse";
import ReactGA from 'react-ga';

// Initialize Google Analytics with your Tracking ID directly
const TRACKING_ID = 'G-QS2QTH05G9'; // Replace with your actual Tracking ID
ReactGA.initialize(TRACKING_ID);

// Custom hook for page tracking (same as before)
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
};

function App() {
  const [page, setPage] = useState("one");
  const [transitioned, setTransitioned] = useState(false);
  const [isSignupCompleted, setIsSignupCompleted] = useState(false);

  const handleScroll = () => {
    if (!transitioned) {
      setPage("two");
      setTransitioned(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [transitioned]);

  return (
    <>
      <Modal show={isSignupCompleted} isClosable={false}>
        <Completed setIsSignupCompleted={setIsSignupCompleted} />
      </Modal>
      {/* <Router> */}
      <Routes>
        <Route
          exact
          path="/"
          element={
            <div className="App">
              <TransitionGroup>
                <CSSTransition key={page} timeout={1000} classNames="fade">
                  {page === "one" ? <LandingPage /> : <Intro />}
                </CSSTransition>
              </TransitionGroup>
            </div>
          }
        />
        <Route path="/construction" element={<ConstuctionLandig />} />
        <Route path="/entertainment" element={<EntertainmentLanding />} />
        <Route path="/healthcare" element={<HealthcareLanding />} />
        <Route path="/event" element={<EventsLanding />} />
        <Route path="/homeService" element={<HomeServicesLanding />} />
        <Route path="/petcare" element={<PetCareLanding />} />
        <Route path="/auth/signup-step-1" element={<SignupMain />} />
        <Route
          path="/auth/signup-step-2"
          element={
            <SignupMain step={2} setIsSignupCompleted={setIsSignupCompleted} />
          }
        />
        <Route path="/auth/login" element={<LoginMain />} />
        <Route
          path="/privacy/termAndCondition"
          element={<TermsAndConditions />}
        />
        <Route path="/privacy/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/privacy/termOfUse" element={<TermOfUse />} />
      </Routes>
      {/* </Router> */}
    </>
  );
}

export default App;
