import React, { useEffect, useState } from 'react';
import styles from './Main.module.css';
import StepOne from '../StepOne/StepOne';
import StepTwo from '../StepTwo/StepTwo';
// import Modal from '../../utils/modal/Modal';
import axios from "axios";

const Main = ({ step = 1, setIsSignupCompleted }) => {

    const [mobileNumber, setMobileNumber] = useState(null);
    const [password, setPassword] = useState(null);
    const [sectors, setSectors] = useState(null);
    const [imageUrl, setImageUrl] = useState("/Assets/Authentication/main.svg");

    // api request

    const baseApiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;
    const fetchSector = async () => {
        try {
            const res = await axios.post(`${baseApiUrl}/api/v1/sectors`, { withCredentials: true });
            console.log(res);
            setSectors(res.data.data.sectors);
        } catch (error) {
            console.log("cannnot fetch sector :", error);
        }
    };

    useEffect(() => {
        fetchSector();
    }, []);

    return (
        <div className={styles.cont}>
            <section className={styles.cont__left}>
                <img src={imageUrl} alt="Signup..." />
            </section>
            <section className={styles.cont__right}>
                {step === 1 && <StepOne setMobileNumber={setMobileNumber} setPassword={setPassword} />}
                {step === 2 && <StepTwo sectors={sectors} setImageUrl={setImageUrl} mobileNumber={mobileNumber} password={password} setIsSignupCompleted={setIsSignupCompleted}/>}
            </section>
        </div>
    );
}

export default Main;