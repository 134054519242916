import React, { useCallback, useState } from 'react';
import styles from './ForgotPassword.module.css';
import OtpInput from '../Otp/OtpInput';
import { LuEye, LuEyeOff } from 'react-icons/lu';
import { LiaSpinnerSolid } from "react-icons/lia";
import { IoCheckmarkSharp } from "react-icons/io5";
import axios from 'axios';

import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const headingFirst = [
    "Forgot your Password?",
    "Enter the OTP",
    "OTP Verified Successfully",
    "Create a new password",
    "Password updated successfully"
];

const headingSecond = [
    "Please enter your registered mobile number ending with <MOBILE_NUMBER> to receive the OTP.",
    "An OTP has been sent to your registered mobile number ending with <MOBILE_NUMBER>. Please check your messages.",
    "OTP verified successfully. You can now create a new password.",
    "Please enter your new password. Your password must be between 6 and 20 characters long for security.",
    "Your password has been successfully updated. You can now log in using your new password."
];

const ForgotPassword = ({ sectorId }) => {
    // stages 0 - 4
    const [stage, setStage] = useState(0);
    const [mobileNumber, setMobileNumber] = useState('');
    const [isMobileValid, setIsMobileValid] = useState(false);
    const [isPassVis1, setIsPassVis1] = useState(false);
    const [isPassVis2, setIsPassVis2] = useState(false);
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [otp, setOtp] = useState("");
    const [isOtpFilled, setIsOtpFilled] = useState(false);
    const [isOtpVerified, setIsOtpVerified] = useState(false);

    const [errors, setErrors] = useState({
        mobileNumber: '',
        otpFilled: false,
        otpVerified: false,
        password: '',
        rePassword: '',
    });

    const togglePassVisibility1 = () => {
        setIsPassVis1(!isPassVis1);
    };

    const togglePassVisibility2 = () => {
        setIsPassVis2(!isPassVis2);
    };

    const validateMobileNumber = (number) => {
        const mobileRegex = /^(?:\+91)?[789]\d{9}$/;
        return mobileRegex.test(number);
    };

    const onMobileChange = (e) => {
        const number = e.target.value;
        setMobileNumber(number);
        const isValid = validateMobileNumber(number);
        setIsMobileValid(isValid);
        setErrors((prevErrors) => ({
            ...prevErrors,
            mobileNumber: isValid ? '' : 'Invalid mobile number',
        }));
    };

    const onPasswordChange = (e) => {
        const pass = e.target.value;
        setPassword(pass);
        validatePasswords(pass);
    };

    const onRePasswordChange = (e) => {
        const rePass = e.target.value;
        setRePassword(rePass);
        comparePassword(password, rePass);
    };

    const validatePasswords = (pass) => {
        const passRegex = /^(?!.*\s).{6,20}$/;
        const isValid = passRegex.test(pass);
        setIsPasswordValid(isValid);
        setErrors((prevErrors) => ({
            ...prevErrors,
            password: isValid ? '' : 'Password must be 6-20 characters long and contain no spaces',
        }));
    };

    const comparePassword = (pass, rePass) => {
        const passwordsMatch = pass === rePass;
        setPasswordMatch(passwordsMatch);
        setErrors((prevErrors) => ({
            ...prevErrors,
            rePassword: passwordsMatch ? '' : 'Passwords do not match',
        }));
    };

    const checkIfOtpFilled = useCallback((isComplete) => {
        setIsOtpFilled(isComplete);
        setErrors((prevErrors) => ({
            ...prevErrors,
            otpFilled: isComplete,
        }));
    }, []);

    const canUpdatePassword = isPasswordValid && passwordMatch;

    const getHeadingSecondText = (stage) => {
        if (stage === 2 && !isOtpVerified) {
            return "";
        }

        const parts = headingSecond[stage].split('<MOBILE_NUMBER>');
        if (parts.length === 2) {
            let last2digit = `**`;
            return (
                <>
                    {parts[0]}<strong key={parts[1]}>{`********${last2digit.slice(-2)}`}</strong>{parts[1]}
                </>
            );
        } else {
            return headingSecond[stage];
        }
    };

    // ------------ api -----------------------

    const baseApiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

    const sendOTP = async () => {
        if (isMobileValid) {
            try {
                const res = await axios.post(`${baseApiUrl}/api/v1/auth/verification/generate`, {
                    mobileNumber,
                    userType: 'Service Provider',
                    useCase: 'Forgot Password'
                }, {
                    withCredentials: true,
                })
                toast.success(res.data.message);
                console.log('OTP Sent :', res);
                // alert('Your verification code is :', res.data.data.code);
                setStage(1);
            } catch (error) {
                toast.error(error.response.data.message);
                console.log('Error sending OTP :', error);
            }
        }
    }

    const verifyOtp = async () => {
        if (isOtpFilled) {
            try {
                const res = await axios.post(`${baseApiUrl}/api/v1/auth/verification/verify`, {
                    mobileNumber,
                    useCase: 'Forgot Password',
                    verificationCode: otp
                }, {
                    withCredentials: true,
                })
                console.log('OTP verified :', res);
                toast.success(res.data.message);
                setStage(2)
                setIsOtpVerified(true);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    otpVerified: true,
                }));
            } catch (error) {
                toast.error(error.response.data.message);
                console.log('Error verifing OTP :', error);
            }
        }
    };

    const updatePassword = async () => {
        if (canUpdatePassword) {
            try {
                const res = await axios.post(`${baseApiUrl}/api/v1/auth/forgot-password/reset`, {
                    mobileNumber,
                    newPassword: password,
                    verificationCode: otp
                }, {
                    withCredentials: true,
                })
                toast.success(res.data.message);
                console.log('Password Updated :', res);
                setStage(4);
            } catch (error) {
                toast.error(error.response.data.message);
                console.log('Error updating password :', error);
            }
        }
    };

    return (
        <div className={`${styles.cont} ${(stage >= 1 && stage < 4) ? styles.contExpand : ''}`}>
            <ToastContainer />
            <img src="/Assets/Authentication/forgotPassword.png" className={styles.topLogo} />
            <section className={styles.topHeading}>
                <text className={`${styles.topHeading__first} ${stage === 4 ? styles.topHeading__first__reduceFontSize : ''} ${(stage === 2 && !isOtpVerified) ? styles.errorHeading : ''}`}>
                    {(stage === 2 && !isOtpVerified) ? 'OTP Verification Failed!' : headingFirst[stage]}
                </text>
                <text className={`${styles.topHeading__second}  ${(stage === 2 && !isOtpVerified) ? styles.errorHeading : ''}`}>
                    {(stage === 2 && !isOtpVerified) ? 'Unsuccessfull OTP verification. Invalid / Expired verification token.' : getHeadingSecondText(stage)}
                </text>
            </section>

            {stage <= 2 && (
                <section className={styles.entries__field}>
                    <section className={styles.entries__field__head}>
                        Mobile number
                    </section>
                    <section className={styles.entries__field__inputVerify}>
                        <input
                            className={stage === 0 ? styles.entries__field__expand : styles.entries__field__shrink}
                            type='text'
                            placeholder='    Enter your mobile number'
                            value={mobileNumber}
                            onChange={onMobileChange}
                            disabled={stage > 0 ? true : false}
                        />
                        <span className={stage > 0 ? styles.entries__field__verifyButton__show : styles.entries__field__verifyButton__hide}>
                            {stage === 1 && <LiaSpinnerSolid className={styles.entries__field__verificationStage1Icon} />}
                            {stage === 2 && isOtpVerified && <IoCheckmarkSharp className={styles.entries__field__verificationStage2Icon} />}
                        </span>
                    </section>
                    {errors.mobileNumber && <p className={styles.error}>{errors.mobileNumber}</p>}
                </section>
            )}

            {/* ------------- OTP ------------- */}

            {(stage === 1 || stage === 2) && (
                <section className={styles.entries__field}>
                    <section className={styles.entries__field__otpHead}>
                        <span>Enter the OTP</span> <span>Resend OTP</span>
                    </section>
                    <section className={styles.entries__field__otpCover}>
                        <OtpInput onOtpComplete={checkIfOtpFilled} finalOtp={setOtp} />
                    </section>
                    {!errors.otpFilled && <p className={styles.error}>Please fill all OTP fields</p>}
                </section>
            )}

            {stage === 3 && (
                <section className={styles.entries__field}>
                    <section className={styles.entries__field__head}>
                        Create your password
                    </section>
                    <section className={styles.entries__field__cover}>
                        <input
                            className={styles.entries__field__expand}
                            type={isPassVis1 ? 'text' : 'password'}
                            placeholder='    Create your password'
                            value={password}
                            onChange={onPasswordChange}
                        />
                        {isPassVis1 ? <LuEye onClick={togglePassVisibility1} className={styles.entries__field__eyeIcon} /> : <LuEyeOff onClick={togglePassVisibility1} className={styles.entries__field__eyeIcon} />}
                        {errors.password && <p className={styles.error}>{errors.password}</p>}
                    </section>
                </section>
            )}

            {stage === 3 && (
                <section className={styles.entries__field}>
                    <section className={styles.entries__field__head}>
                        Re-enter your password
                    </section>
                    <section className={styles.entries__field__cover}>
                        <input
                            className={styles.entries__field__expand}
                            type={isPassVis2 ? 'text' : 'password'}
                            placeholder='   Re-enter your password'
                            value={rePassword}
                            onChange={onRePasswordChange}
                        />
                        {isPassVis2 ? <LuEye onClick={togglePassVisibility2} className={styles.entries__field__eyeIcon} /> : <LuEyeOff onClick={togglePassVisibility2} className={styles.entries__field__eyeIcon} />}
                        {errors.rePassword && <p className={styles.error}>{errors.rePassword}</p>}
                    </section>
                </section>
            )}

            {stage === 0 && (
                <button onClick={sendOTP} className={styles.bottomButton} disabled={!isMobileValid}>
                    Send OTP
                    {/* Next */}
                </button>
            )}
            {stage === 1 && (
                <button onClick={verifyOtp} className={styles.bottomButton} disabled={!isOtpFilled}>
                    Verify
                </button>
            )}
            {stage === 2 && (
                <button onClick={() => { if (isOtpVerified) setStage(3); }} className={styles.bottomButton} disabled={!isOtpVerified}>
                    Create a new password
                </button>
            )}
            {stage === 3 && (
                <button onClick={updatePassword} className={styles.bottomButton} disabled={!canUpdatePassword}>
                    Update password
                </button>
            )}
            {stage === 4 && (
                <button className={styles.bottomButton}>
                    Login
                </button>
            )}
        </div>
    );
};

export default ForgotPassword;