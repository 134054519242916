import { useEffect, useState } from "react";
import styles from "./StepTwo.module.css";
import { FaAngleRight, FaChevronDown } from "react-icons/fa";
import axios from "axios";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StepTwo = ({
  mobileNumber,
  password,
  sectors = [],
  setIsSignupCompleted,
}) => {
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [data, setData] = useState({
    fullName: "",
    emailAddress: "",
    userType: "",
    sectorId: "",
    categoryId: "",
  });

  const [errors, setErrors] = useState({
    fullName: "",
    emailAddress: "",
    userType: "",
    sector: "",
    category: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, " ", value);
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!data.fullName.trim()) {
      errors.fullName = "Full Name is required";
      isValid = false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!data.emailAddress.trim()) {
      errors.emailAddress = "Email Address is required";
      isValid = false;
    } else if (!emailPattern.test(data.emailAddress)) {
      errors.emailAddress = "Enter a valid Email Address";
      isValid = false;
    }

    if (!data.userType) {
      errors.userType = "User Type is required";
      isValid = false;
    }

    if (!data.sectorId) {
      errors.sector = "Sector is required";
      isValid = false;
    }

    if (!data.categoryId) {
      errors.category = "Category is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const baseApiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

  const signupHandler = async () => {
    if (validate()) {
      try {
        const res = await axios.post(
          `${baseApiUrl}/api/v1/auth/signup`,
          {
            mobileNumber: mobileNumber,
            password: password,
            userType: data.userType,
            fullName: data.fullName,
            emailAddress: data.emailAddress,
            sectorId: data.sectorId,
            categoryId: data.categoryId,
          },
          {
            withCredentials: true,
          }
        );

        setIsSignupCompleted(true);
        console.log(res);
      } catch (error) {
        console.error("Signup error:", error);
        toast.error(error.response.data.message);
      }
    }
  };

  const fetchcategory = async () => {
    try {
      const res = await axios.post(
        `${baseApiUrl}/api/v1/categories/sector-wise`,
        {
          sectorId: data.sectorId,
        },
        {
          withCredentials: true,
        }
      );
      setCategoriesOptions(res.data.data.categories);
    } catch (error) {
      console.error("Signup error:", error);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (data.sectorId !== "") {
      fetchcategory();
    }
  }, [data.sectorId]);

  return (
    <div className={styles.cont}>
      <ToastContainer />
      <h3 className={styles.top__heading}>Personal Details</h3>
      <section className={styles.signup__first}>
        <section className={styles.signup__entries}>
          <label>Full Name</label>
          <input
            placeholder="Enter your Full name"
            type="text"
            name="fullName"
            value={data.fullName}
            onChange={handleChange}
          />
          {errors.fullName && (
            <div className={styles.error}>{errors.fullName}</div>
          )}
        </section>
        <section className={styles.signup__entries}>
          <label>Mobile Number</label>
          <input
            value={mobileNumber}
            type="number"
            name="mobileNumber"
            disabled={true}
          />
        </section>
        <section className={styles.signup__entries}>
          <label>Email Address</label>
          <input
            placeholder="Enter your Email address"
            type="email"
            name="emailAddress"
            value={data.emailAddress}
            onChange={handleChange}
          />
          {errors.emailAddress && (
            <div className={styles.error}>{errors.emailAddress}</div>
          )}
        </section>
      </section>
      <section className={styles.signup__second}>
        <section className={styles.signup__entries}>
          <h3 className={styles.signup__entries__title}>Select User</h3>
          <p className={styles.signup__entries__description}>
            Individual or Business
          </p>
          <section className={styles.signup__entries__select__wrapper}>
            <FaChevronDown className={styles.signup__entries__dropIcon} />
            <select
              name="userType"
              value={data.userType}
              onChange={handleChange}
            >
              <option value="">Choose user type</option>
              <option value="Individual">Individual</option>
              <option value="Business">Business</option>
            </select>
            {errors.userType && (
              <div className={styles.error}>{errors.userType}</div>
            )}
          </section>
        </section>
        <section className={styles.signup__entries}>
          <h3 className={styles.signup__entries__title}>Select Sectors</h3>
          <p className={styles.signup__entries__description}>
            To start your project we need to customize your preferences. Please,
            select your user type.
          </p>
          <section className={styles.signup__entries__select__wrapper}>
            <FaChevronDown className={styles.signup__entries__dropIcon} />
            <select
              name="sectorId"
              value={data.sectorId}
              onChange={handleChange}
            >
              <option value="">Choose a sector</option>
              {sectors &&
                sectors.map((ele) => {
                  return <option value={ele.id}>{ele.sectorName}</option>;
                })}
            </select>
            {errors.sector && (
              <div className={styles.error}>{errors.sector}</div>
            )}
          </section>
        </section>
        <section className={styles.signup__entries}>
          <h3 className={styles.signup__entries__title}>
            What role do you have?
          </h3>
          <p className={styles.signup__entries__description}>
            To start your project we need to customize your preferences. Please,
            select your user type.
          </p>
          <section className={styles.signup__entries__select__wrapper}>
            <FaChevronDown className={styles.signup__entries__dropIcon} />
            <select
              name="categoryId"
              value={data.categoryId}
              onChange={handleChange}
            >
              <option value="">Choose a category</option>
              {categoriesOptions &&
                categoriesOptions.map((ele) => {
                  return <option value={ele.id}>{ele.categoryName}</option>;
                })}
            </select>
            {errors.category && (
              <div className={styles.error}>{errors.category}</div>
            )}
          </section>
        </section>
        <button onClick={signupHandler} className={styles.nextBtn}>
          Create an account
          <FaAngleRight />
        </button>
      </section>
      <section className={styles.separation} />
    </div>
  );
};

export default StepTwo;
